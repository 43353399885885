.css-1t1j96h-MuiPaper-root-MuiDialog-paper{    //localhost modal width height
    max-width: 57rem !important;
    width: 48rem !important;
    height: 20rem !important;
}
.css-uhb5lp{                                   //Amplify modal width height
    max-width: 57rem !important;
    width: 48rem !important;
    height: 20rem !important;
}
.header-bg-color{
    background-color:#dee2e6 !important;
}


//modal header bg-color change
.ant-modal-header{
    background-color: #dee2e6 !important;
}
.ant-modal-title{
    font-weight: 700;
}